<template>
    <div class="d-flex flex-column flex-root">
        <div
                class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
                :class="{
        'login-signin-on': this.state == 'signin',
        'login-signin-challenge-on': this.state == 'signin_challenge',
        'login-forgot-on': this.state == 'forgot',
        'login-signup-on': this.state == 'reset',
      }"
                id="kt_login"
        >
            <!--begin::Aside-->
            <div
                    class="login-aside d-flex flex-column flex-row-auto flex-center"
                    style="background-color: #585857"
            >
                <div class="d-flex flex-column-auto flex-column">
                    <a href="#" class="text-center mb-10">
                        <img src="media/logos/logo_large.png" class="max-h-100px" alt=""/>
                    </a>
                </div>
            </div>
            <!--begin::Aside-->
            <!--begin::Content-->
            <div
                    class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
            >
                <div class="d-flex flex-column-fluid flex-center">
                    <!--begin::Signin-->
                    <div class="login-form login-signin">
                        <form
                                class="form"
                                novalidate="novalidate"
                                id="kt_login_signin_form"
                        >
                            <div class="pb-13 pt-lg-0 pt-5">
                                <h3
                                        class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                                >
                                    {{ $t("AUTH.WELCOME") }}
                                </h3>
                            </div>
                            <div class="form-group">
                                <label class="font-size-h6 font-weight-bolder text-dark">{{
                                    $t("AUTH.EMAIL")
                                    }}</label>
                                <div
                                        id="example-input-group-1"
                                        label=""
                                        label-for="example-input-1"
                                >
                                    <input
                                            class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                                            type="text"
                                            name="email"
                                            ref="email"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="d-flex justify-content-between mt-n5">
                                    <label
                                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                                    >{{ $t("AUTH.PASSWORD") }}</label
                                    >
                                    <a
                                            class="text-primary font-size-h6 font-weight-bolder text-hover-light-primary pt-5 cursor-pointer"
                                            id="kt_login_forgot"
                                            @click="showForm('forgot')"
                                    >{{ $t("AUTH.FORGOT") }}</a
                                    >
                                </div>
                                <div
                                        id="example-input-group-2"
                                        label=""
                                        label-for="example-input-2"
                                >
                                    <input
                                            class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                                            type="password"
                                            name="password"
                                            ref="password"
                                            autocomplete="off"
                                    />
                                </div>
                            </div>
                            <div class="pb-lg-0 pb-5">
                                <button
                                        ref="kt_login_signin_submit"
                                        class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                                >
                                    {{ $t("AUTH.LOGIN") }}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="login-form login-signin-challenge">
                        <form
                                class="form"
                                novalidate="novalidate"
                                id="kt_login_signin_challenge_form"
                        >
                            <div class="pb-13 pt-lg-0 pt-5">
                                <h3
                                        class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                                >
                                    {{ $t("AUTH.CHALLENGE_NEW_PASSWORD") }}
                                </h3>
                                <p class="text-muted font-weight-bold font-size-h4">
                                    {{ $t("AUTH.CHALLENGE_NEW_PASSWORD_SUBHEADER") }}
                                </p>
                            </div>
                            <div class="form-group">
                                <label class="font-size-h6 font-weight-bolder text-dark">{{
                                    $t("AUTH.NEW_PASSWORD")
                                    }}</label>
                                <input
                                        class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                                        type="password"
                                        placeholder="New Password"
                                        name="newpassword"
                                        ref="newpassword"
                                        autocomplete="off"
                                />
                            </div>
                            <div class="form-group">
                                <label class="font-size-h6 font-weight-bolder text-dark">{{
                                    $t("AUTH.NEW_PASSWORD_CONFIRM")
                                    }}</label>
                                <input
                                        class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                                        type="password"
                                        placeholder="New Password (confirmation)"
                                        name="newpasswordrepeat"
                                        ref="newpasswordrepeat"
                                        autocomplete="off"
                                />
                            </div>
                            <div class="pb-lg-0 pb-5">
                                <button
                                        ref="kt_login_signin_challenge_submit"
                                        class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                                >
                                    {{ $t("AUTH.LOGIN") }}
                                </button>
                            </div>
                        </form>
                    </div>
                    <!--end::Signin-->
                    <!--begin::Forgot-->
                    <div class="login-form login-forgot">
                        <!--begin::Form-->
                        <form
                                class="form"
                                novalidate="novalidate"
                                id="kt_login_forgot_form"
                        >
                            <div class="pb-13 pt-lg-0 pt-5">
                                <h3
                                        class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                                >
                                    {{ $t("AUTH.FORGOT") }}
                                </h3>
                                <p class="text-muted font-weight-bold font-size-h4">
                                    {{ $t("AUTH.FORGOT_SUBHEADER") }}
                                </p>
                            </div>
                            <div class="form-group">
                                <label class="font-size-h6 font-weight-bolder text-dark">{{
                                    $t("AUTH.EMAIL")
                                    }}</label>
                                <input
                                        class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                                        type="email"
                                        name="email"
                                        ref="forgotemail"
                                        autocomplete="off"
                                />
                            </div>
                            <div class="form-group d-flex flex-wrap pb-lg-0">
                                <button
                                        ref="kt_login_forgot_submit"
                                        class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-4"
                                >
                                    {{ $t("SUBMIT") }}
                                </button>
                                <button
                                        type="button"
                                        id="kt_login_forgot_cancel"
                                        class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                                        @click="showForm('signin')"
                                >
                                    {{ $t("CANCEL") }}
                                </button>
                            </div>
                        </form>
                    </div>
                    <!--end::Forgot-->
                    <!--begin::Reset-->
                    <div class="login-form login-signup">
                        <!--begin::Form-->
                        <form class="form" novalidate="novalidate" id="kt_login_reset_form">
                            <div class="pb-13 pt-lg-0 pt-5">
                                <h3
                                        class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                                >
                                    {{ $t("AUTH.RESET") }}
                                </h3>
                            </div>
                            <div class="form-group">
                                <label class="font-size-h6 font-weight-bolder text-dark">{{
                                    $t("AUTH.EMAIL")
                                    }}</label>
                                <input
                                        class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                                        type="email"
                                        placeholder="Email"
                                        name="resetemail"
                                        ref="resetemail"
                                        autocomplete="off"
                                />
                            </div>
                            <div class="form-group">
                                <label class="font-size-h6 font-weight-bolder text-dark">{{
                                    $t("AUTH.NEW_PASSWORD")
                                    }}</label>
                                <input
                                        class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                                        type="password"
                                        placeholder="New Password"
                                        name="resetpassword"
                                        ref="resetpassword"
                                        autocomplete="off"
                                />
                            </div>
                            <div class="form-group">
                                <label class="font-size-h6 font-weight-bolder text-dark">{{
                                    $t("AUTH.NEW_PASSWORD_CONFIRM")
                                    }}</label>
                                <input
                                        class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                                        type="password"
                                        placeholder="New Password (confirmation)"
                                        name="resetpasswordrepeat"
                                        ref="resetpasswordrepeat"
                                        autocomplete="off"
                                />
                            </div>
                            <div class="form-group d-flex flex-wrap pb-lg-0">
                                <button
                                        ref="kt_login_reset_submit"
                                        class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-4"
                                >
                                    {{ $t("SUBMIT") }}
                                </button>
                                <button
                                        type="button"
                                        id="kt_login_reset_cancel"
                                        class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                                        @click="showForm('signin')"
                                >
                                    {{ $t("CANCEL") }}
                                </button>
                            </div>
                        </form>
                    </div>
                    <!--end::Reset-->
                </div>
                <!--begin::Content footer-->
                <div
                        class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
                >
                    <a
                            href="https://www.xiphoo.com"
                            rel="noopener"
                            target="_blank"
                            class="text-primary font-weight-bolder font-size-h5"
                    >{{ $t("IMPRINT") }}</a
                    >
                    <a
                            href="https://www.xiphoo.com"
                            rel="noopener"
                            target="_blank"
                            class="text-primary ml-10 font-weight-bolder font-size-h5"
                    >{{ $t("PRIVACY") }}</a
                    >
                    <a
                            href="mailto:info@xiphoo.com"
                            class="text-primary ml-10 font-weight-bolder font-size-h5"
                    >{{ $t("CONTACT") }}</a
                    >
                </div>
                <!--end::Content footer-->
            </div>
            <!--end::Content-->
        </div>
    </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
    @import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
    import {mapGetters} from "vuex";
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

    // FormValidation plugins
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

    import KTUtil from "@/assets/js/components/util";
    import {
        LOGIN,
        LOGOUT,
        RESET_PASSWORD,
        SET_PASSWORD,
    } from "@/core/services/store/auth.module";
    import Swal from "sweetalert2";

    export default {
        name: "login",
        data() {
            return {
                state: this.$route.query.code ? "reset" : "signin",
            };
        },
        mounted() {
            const signin_form = KTUtil.getById("kt_login_signin_form");
            const signin_challenge_form = KTUtil.getById(
                "kt_login_signin_challenge_form"
            );
            const forgot_form = KTUtil.getById("kt_login_forgot_form");
            const reset_form = KTUtil.getById("kt_login_reset_form");

            this.fv = formValidation(signin_form, {
                fields: {
                    email: {
                        validators: {
                            notEmpty: {
                                message: this.$t("VALIDATION.REQUIRED", {
                                    name: this.$t("AUTH.EMAIL"),
                                }),
                            },
                            emailAddress: {
                                message: this.$t("VALIDATION.EMAIL", {
                                    name: this.$t("AUTH.EMAIL"),
                                }),
                            },
                        },
                    },
                    password: {
                        validators: {
                            notEmpty: {
                                message: this.$t("VALIDATION.REQUIRED", {
                                    name: this.$t("AUTH.PASSWORD"),
                                }),
                            },
                        },
                    },
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap(),
                },
            });

            this.fv_challenge = formValidation(signin_challenge_form, {
                fields: {
                    newpassword: {
                        validators: {
                            notEmpty: {
                                message: this.$t("VALIDATION.REQUIRED", {
                                    name: this.$t("AUTH.NEW_PASSWORD"),
                                }),
                            },
                            regexp: {
                                regexp: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{12,}$/,
                                message: this.$t("VALIDATION.PASSWORD", {
                                    name: this.$t("AUTH.NEW_PASSWORD"),
                                }),
                            },
                        },
                    },
                    newpasswordrepeat: {
                        validators: {
                            notEmpty: {
                                message: this.$t("VALIDATION.REQUIRED", {
                                    name: this.$t("AUTH.NEW_PASSWORD_CONFIRM"),
                                }),
                            },
                            identical: {
                                compare: function () {
                                    return signin_challenge_form.querySelector(
                                        '[name="newpassword"]'
                                    ).value;
                                },
                                message: this.$t("VALIDATION.IDENTICAL", {
                                    name: this.$t("AUTH.NEW_PASSWORD"),
                                    name_compare: this.$t("AUTH.NEW_PASSWORD_CONFIRM"),
                                }),
                            },
                        },
                    },
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap(),
                },
            });

            this.fv2 = formValidation(forgot_form, {
                fields: {
                    email: {
                        validators: {
                            notEmpty: {
                                message: this.$t("VALIDATION.REQUIRED", {
                                    name: this.$t("AUTH.EMAIL"),
                                }),
                            },
                            emailAddress: {
                                message: this.$t("VALIDATION.EMAIL", {
                                    name: this.$t("AUTH.EMAIL"),
                                }),
                            },
                        },
                    },
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap(),
                },
            });

            this.fv3 = formValidation(reset_form, {
                fields: {
                    resetemail: {
                        validators: {
                            notEmpty: {
                                message: this.$t("VALIDATION.REQUIRED", {
                                    name: this.$t("AUTH.EMAIL"),
                                }),
                            },
                            emailAddress: {
                                message: this.$t("VALIDATION.EMAIL", {
                                    name: this.$t("AUTH.EMAIL"),
                                }),
                            },
                        },
                    },
                    resetpassword: {
                        validators: {
                            notEmpty: {
                                message: this.$t("VALIDATION.REQUIRED", {
                                    name: this.$t("AUTH.NEW_PASSWORD"),
                                }),
                            },
                            regexp: {
                                regexp: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{12,}$/,
                                message: this.$t("VALIDATION.PASSWORD", {
                                    name: this.$t("AUTH.NEW_PASSWORD"),
                                }),
                            },
                        },
                    },
                    resetpasswordrepeat: {
                        validators: {
                            notEmpty: {
                                message: this.$t("VALIDATION.REQUIRED", {
                                    name: this.$t("AUTH.NEW_PASSWORD_CONFIRM"),
                                }),
                            },
                            identical: {
                                compare: function () {
                                    return reset_form.querySelector('[name="resetpassword"]').value;
                                },
                                message: this.$t("VALIDATION.IDENTICAL", {
                                    name: this.$t("AUTH.NEW_PASSWORD"),
                                    name_compare: this.$t("AUTH.NEW_PASSWORD_CONFIRM"),
                                }),
                            },
                        },
                    },
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap(),
                },
            });

            this.fv.on("core.form.valid", () => {
                const email = this.$refs.email.value;
                const password = this.$refs.password.value;

                // clear existing errors
                this.$store.dispatch(LOGOUT);

                // set spinner to submit button
                const submitButton = this.$refs["kt_login_signin_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                // send login request
                this.$store
                    .dispatch(LOGIN, {email: email, password: password})
                    // go to which page after successfully login
                    .then((success) => {
                        if (success) {
                            this.$router.push({name: "products"});
                        } else if (
                            this.currentChallenge.challenge === "NEW_PASSWORD_REQUIRED"
                        ) {
                            this.showForm("signin_challenge");
                        } else {
                            submitButton.classList.remove(
                                "spinner",
                                "spinner-light",
                                "spinner-right"
                            );
                            Swal.fire({
                                title: "",
                                text: this.$t("AUTH.LOGIN_FAIL"),
                                icon: "error",
                                buttonsStyling: false,
                                customClass: {
                                    confirmButton: "btn btn-light-primary",
                                },
                                confirmButtonText: this.$t("CLOSE"),
                                heightAuto: false,
                            });
                        }
                    });
            });

            this.fv_challenge.on("core.form.valid", () => {
                const new_password = this.$refs.newpassword.value;

                // set spinner to submit button
                const submitButton = this.$refs["kt_login_signin_challenge_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                this.$store
                    .dispatch(LOGIN, {
                        challenge: this.currentChallenge.challenge,
                        email: this.currentChallenge.email,
                        password: new_password,
                        session: this.currentChallenge.session,
                    })
                    .then((success) => {
                        if (success) {
                            this.$router.push({name: "products"});
                        } else {
                            this.showForm("signin");
                            submitButton.classList.remove(
                                "spinner",
                                "spinner-light",
                                "spinner-right"
                            );
                            Swal.fire({
                                title: "",
                                text: this.$t("AUTH.LOGIN_FAIL"),
                                icon: "error",
                                buttonsStyling: false,
                                customClass: {
                                    confirmButton: "btn btn-light-primary",
                                },
                                confirmButtonText: this.$t("CLOSE"),
                                heightAuto: false,
                            });
                        }
                    });
            });

            this.fv2.on("core.form.valid", () => {
                const email = this.$refs.forgotemail.value;

                // clear existing errors
                this.$store.dispatch(LOGOUT);

                // set spinner to submit button
                const submitButton = this.$refs["kt_login_forgot_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                // send login request
                this.$store.dispatch(RESET_PASSWORD, {email: email}).then((success) => {
                    if (success) {
                        submitButton.classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                        Swal.fire({
                            title: "",
                            text: this.$t("AUTH.FORGOT_SUCCESS"),
                            icon: "success",
                            buttonsStyling: false,
                            customClass: {
                                confirmButton: "btn btn-light-primary",
                            },
                            confirmButtonText: this.$t("CLOSE"),
                            heightAuto: false,
                        });
                        this.showForm("signin");
                    } else {
                        submitButton.classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                        Swal.fire({
                            title: "",
                            text: this.$t("AUTH.FORGOT_FAIL"),
                            icon: "error",
                            buttonsStyling: false,
                            customClass: {
                                confirmButton: "btn btn-light-primary",
                            },
                            confirmButtonText: this.$t("CLOSE"),
                            heightAuto: false,
                        });
                    }
                });
            });

            this.fv3.on("core.form.valid", () => {
                const email = this.$refs.resetemail.value;
                const password = this.$refs.resetpassword.value;
                const code = this.$route.query.code;

                // clear existing errors
                this.$store.dispatch(LOGOUT);

                // set spinner to submit button
                const submitButton = this.$refs["kt_login_reset_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                // send login request
                this.$store
                    .dispatch(SET_PASSWORD, {
                        email: email,
                        code: code,
                        password: password,
                    })
                    .then((success) => {
                        if (success) {
                            submitButton.classList.remove(
                                "spinner",
                                "spinner-light",
                                "spinner-right"
                            );
                            Swal.fire({
                                title: "",
                                text: this.$t("AUTH.RESET_SUCCESS"),
                                icon: "success",
                                buttonsStyling: false,
                                customClass: {
                                    confirmButton: "btn btn-light-primary",
                                },
                                confirmButtonText: this.$t("CLOSE"),
                                heightAuto: false,
                            });
                            this.showForm("signin");
                        } else {
                            submitButton.classList.remove(
                                "spinner",
                                "spinner-light",
                                "spinner-right"
                            );
                            Swal.fire({
                                title: "",
                                text: this.$t("AUTH.RESET_FAIL"),
                                icon: "error",
                                buttonsStyling: false,
                                customClass: {
                                    confirmButton: "btn btn-light-primary",
                                },
                                confirmButtonText: this.$t("CLOSE"),
                                heightAuto: false,
                            });
                        }
                    });
            });

            this.fv.on("core.form.invalid", () => {
            });

            this.fv_challenge.on("core.form.invalid", () => {
            });

            this.fv2.on("core.form.invalid", () => {
            });

            this.fv3.on("core.form.invalid", () => {
            });
        },
        methods: {
            showForm(form) {
                this.$router.replace({query: null});
                this.state = form;
                var form_name = "kt_login_" + form + "_form";
                KTUtil.animateClass(
                    KTUtil.getById(form_name),
                    "animate__animated animate__backInUp"
                );
            },
        },
        computed: {
            ...mapGetters(["currentChallenge"]),
        },
    };
</script>
